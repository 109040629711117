import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../context/auth/authContext";
import AlertContext from "../../context/alert/alertContext";
import TelegramLoginButton from "react-telegram-login";
import styled from "styled-components";

import { Button } from "./AuthBtnStyle";

const TelegramWidgetContainer = styled.div`
  position: absolute;
  z-index: 400;
  padding: 1rem;
  background-color: ${(props) => props.theme.BACKGROUND};
  box-shadow: 1px 1px 4px ${(props) => props.theme.BOX_SHADOW};
  display: flex;
  justify-content: space-between;

  i {
    margin-left: 1rem;
    cursor: pointer;

    :hover {
      color: ${(props) => props.theme.PRIMARY};
    }
  }
`;

const TelegramAuthBtn = ({ btnType }) => {
  const { login, register } = useContext(AuthContext);
  const { pushAlert } = useContext(AlertContext);
  const [btnTitle, setBtnTitle] = useState();
  const [showWidget, setShowWidget] = useState(false);

  useEffect(() => {
    if (btnType === "login") {
      setBtnTitle("Войти с помощью Telegram");
    } else if (btnType === "register")
      setBtnTitle("Зарегистрироваться с помощью Telegram");
  }, []);

  const handleClick = () => {
    setShowWidget(!showWidget);
  };

  // if user logs in wigh telegram, log in or register new user
  // if trying to register, uses login() if user is already registered
  // show error message if telegram didn't respond right
  const responseTelegram = (response) => {
    const { first_name, last_name, id, username } = response;
    const name =
      first_name && last_name ? first_name + " " + last_name : username;
    const stringifiedId = String(id);
    if (response.id) {
      if (btnType === "login") {
        login({ name, external_id: stringifiedId });
      } else if (btnType === "register")
        register({ name, external_id: stringifiedId });
      setShowWidget(false);
    } else {
      pushAlert({
        type: "danger",
        text:
          "Ошибка авторизации через Telegram. Проверьте логин, пароль и попробуйте снова",
      });
    }
  };
  return (
    <>
      <Button onClick={handleClick} title={btnTitle}>
        <i class="fab fa-telegram-plane"></i>
      </Button>
      {showWidget && (
        <TelegramWidgetContainer>
          <TelegramLoginButton
            dataOnauth={responseTelegram}
            botName="WishLisBot"
          />
          <div onClick={() => setShowWidget(false)}>
            <i class="fas fa-times"></i>
          </div>
        </TelegramWidgetContainer>
      )}
    </>
  );
};

export default TelegramAuthBtn;
