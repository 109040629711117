import React from "react";
import FacebookAuthBtn from "./FacebookAuthBtn";
import GoogleAuthBtn from "./GoogleAuthBtn";
import VKAuthBtn from "./VKAuthBtn";
import TelegramAuthBtn from "./TelegramAuthBtn";
import styled from "styled-components";

const AuthScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 25%;
`;

const BtnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
`;

const AuthScreen = ({ type, title }) => {
  return (
    <AuthScreenContainer>
      <h3>{title}</h3>
      <BtnsContainer>
        <GoogleAuthBtn btnType={type} />
        <FacebookAuthBtn btnType={type} />
        <VKAuthBtn btnType={type} />
        <TelegramAuthBtn btnType={type} />
      </BtnsContainer>
    </AuthScreenContainer>
  );
};

export default AuthScreen;
