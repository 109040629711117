import React from "react";

const LogoIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 512 513"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M415.89 27C361.346 27 316.973 71.3753 316.973 125.918V135.103L438.862 197.265V27H415.89Z"
          fill="#FF7B49"
        />
        <path
          d="M415.89 27C361.346 27 316.973 71.3753 316.973 125.918V135.103L438.862 197.265V27H415.89Z"
          fill="#FD4B57"
        />
        <path
          d="M96.111 27H73.1387V197.265L195.028 135.103V125.918C195.028 71.3743 150.654 27 96.111 27V27Z"
          fill="#FF7B49"
        />
        <path
          d="M47.9221 243.179L0 381.739L244.653 455.552L47.9221 243.179Z"
          fill="#FFF9E1"
        />
        <path
          d="M464.079 243.179L267.347 455.553L512 381.739L464.079 243.179Z"
          fill="#FFF9E1"
        />
        <path
          d="M464.079 243.179L267.347 455.553L512 381.739L464.079 243.179Z"
          fill="#FFDEA8"
        />
        <path
          d="M183.265 433.413L214.79 467.444C225.386 478.883 240.406 485.444 256.001 485.444C271.595 485.444 286.615 478.883 297.211 467.444L328.736 433.413C308.618 416.833 282.915 407.733 256.002 407.733C229.089 407.734 203.384 416.834 183.265 433.413V433.413Z"
          fill="#874208"
        />
        <path
          d="M256.001 407.734V485.444C271.595 485.444 286.615 478.883 297.211 467.444L328.736 433.413C308.617 416.834 282.914 407.734 256.001 407.734V407.734Z"
          fill="#682A00"
        />
        <path
          d="M438.039 167.884C426.256 133.814 394.118 110.923 358.067 110.923H153.935C117.884 110.923 85.7466 133.814 73.9635 167.884L36.8088 275.313L203.693 455.466C218.246 444.001 236.661 437.724 256.001 437.724C275.34 437.724 293.757 444.001 308.308 455.465L475.192 275.313L438.039 167.884Z"
          fill="#FFB655"
        />
        <path
          d="M438.039 167.884C426.256 133.814 394.118 110.923 358.067 110.923H256.001V437.724C275.34 437.724 293.756 444.001 308.308 455.465L475.192 275.313L438.039 167.884Z"
          fill="#FF7B49"
        />
        <path
          d="M403.498 243.089L403.501 243.09C404.131 243.147 404.698 243.579 404.912 244.249L409.674 242.724L404.914 244.254C405.132 244.935 404.924 245.661 404.414 246.111L404.41 246.115L377.139 270.268L374.929 272.227L375.576 275.108L383.617 310.886C383.617 310.887 383.617 310.887 383.618 310.888C383.775 311.593 383.499 312.291 382.959 312.686L382.959 312.687C382.427 313.076 381.748 313.098 381.207 312.769L381.195 312.762L350.085 293.975L347.501 292.414L344.916 293.974L313.795 312.762L313.793 312.763C313.517 312.93 313.239 313 312.974 313C312.661 313 312.335 312.904 312.03 312.682C311.499 312.293 311.219 311.6 311.378 310.886C311.378 310.885 311.378 310.884 311.379 310.884L319.419 275.108L320.067 272.227L317.856 270.269L290.585 246.11L290.576 246.102C290.079 245.664 289.866 244.941 290.087 244.257C290.304 243.581 290.87 243.149 291.503 243.089C291.504 243.089 291.505 243.089 291.506 243.088L327.581 239.78L330.563 239.507L331.73 236.749L345.994 203.032C345.994 203.031 345.994 203.03 345.995 203.029C346.275 202.37 346.878 202 347.501 202C348.125 202 348.727 202.372 349.007 203.026C349.008 203.027 349.009 203.029 349.009 203.03L363.271 236.749L364.438 239.507L367.42 239.78L403.498 243.089Z"
          fill="#FAFF00"
          stroke="white"
          strokeWidth="10"
        />
        <path
          d="M223.498 243.089L223.501 243.09C224.131 243.147 224.698 243.579 224.912 244.249L229.674 242.724L224.914 244.254C225.132 244.935 224.924 245.661 224.414 246.111L224.41 246.115L197.139 270.268L194.929 272.227L195.576 275.108L203.617 310.886C203.617 310.887 203.617 310.887 203.618 310.888C203.775 311.593 203.499 312.291 202.959 312.686L202.959 312.687C202.427 313.076 201.748 313.098 201.207 312.769L201.195 312.762L170.085 293.975L167.501 292.414L164.916 293.974L133.795 312.762L133.793 312.763C133.517 312.93 133.239 313 132.974 313C132.661 313 132.335 312.904 132.03 312.682C131.499 312.293 131.219 311.6 131.378 310.886C131.378 310.885 131.378 310.884 131.379 310.884L139.419 275.108L140.067 272.227L137.856 270.269L110.585 246.11L110.576 246.102C110.079 245.664 109.866 244.941 110.087 244.257C110.304 243.581 110.87 243.149 111.503 243.089C111.504 243.089 111.505 243.089 111.506 243.088L147.581 239.78L150.563 239.507L151.73 236.749L165.994 203.032C165.994 203.031 165.994 203.03 165.995 203.029C166.275 202.37 166.878 202 167.501 202C168.125 202 168.727 202.372 169.007 203.026C169.008 203.027 169.009 203.029 169.009 203.03L183.271 236.749L184.438 239.507L187.42 239.78L223.498 243.089Z"
          fill="#FAFF00"
          stroke="white"
          strokeWidth="10"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="512" height="512.444" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoIcon;
