import React, { useState, useEffect, useContext } from "react";
import GoogleLogin from "react-google-login";
import AuthContext from "../../context/auth/authContext";
import AlertContext from "../../context/alert/alertContext";

import { Button } from "./AuthBtnStyle";

const GoogleAuthBtn = ({ btnType }) => {
  const { login, register } = useContext(AuthContext);
  const { pushAlert } = useContext(AlertContext);
  const [btnTitle, setBtnTitle] = useState();

  useEffect(() => {
    if (btnType === "login") {
      setBtnTitle("Войти с учетной записью Google");
    } else if (btnType === "register")
      setBtnTitle("Зарегистрироваться с учетной записью Google");
  }, []);

  // if user logs in wigh google, register new user
  // falls back to login() if user is already registered
  const onSuccess = (response) => {
    const { name, googleId } = response.profileObj;
    if (btnType === "login") {
      login({ name, external_id: googleId });
    } else if (btnType === "register")
      register({ name, external_id: googleId });
  };

  // show alert if logging in with google didn't go well
  const onFailure = (r) => {
    console.log(r);
    pushAlert({
      type: "danger",
      text:
        "Ошибка авторизации через Google. Проверьте логин, пароль и попробуйте снова",
    });
  };

  return (
    <GoogleLogin
      onSuccess={onSuccess}
      onFailure={onFailure}
      clientId={
        process.env.NODE_ENV === "production"
          ? "917180155454-0a7fpfkdrir4naoav2vf35eftopihain.apps.googleusercontent.com"
          : "917180155454-0pnj9a30f6v15qkukbgumqcv4phbskhd.apps.googleusercontent.com"
      }
      cookiePolicy="single_host_origin"
      render={(renderProps) => (
        <Button
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          title={btnTitle}
        >
          <i class="fab fa-google"></i>
        </Button>
      )}
    />
  );
};

export default GoogleAuthBtn;
