import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../context/auth/authContext";
import AlertContext from "../../context/alert/alertContext";

import { Button } from "./AuthBtnStyle";

// to make VK api bindings work
/* global VK */

const VKAuthBtn = ({ btnType }) => {
  const { login, register } = useContext(AuthContext);
  const { pushAlert } = useContext(AlertContext);
  const [btnTitle, setBtnTitle] = useState();

  useEffect(() => {
    if (btnType === "login") {
      setBtnTitle("Войти с учетной записью VK");
    } else if (btnType === "register")
      setBtnTitle("Зарегистрироваться с учетной записью VK");
  }, []);

  // if user logs in wigh facebook, log in or register new user
  // if trying to register, uses login() if user is already registered
  // show error message if facebook didn't respond right
  const responseVK = (response) => {
    const {
      session: {
        user: { first_name, last_name, id },
      },
    } = response;
    const name = first_name + " " + last_name;
    if (response.session.user.id) {
      if (btnType === "login") {
        login({ name, external_id: id });
      } else if (btnType === "register") register({ name, external_id: id });
    } else {
      pushAlert({
        type: "danger",
        text:
          "Ошибка авторизации через VK. Проверьте логин, пароль и попробуйте снова",
      });
    }
  };
  return (
    <Button
      onClick={() => VK.Auth.login((r) => responseVK(r))}
      title={btnTitle}
    >
      <i class="fab fa-vk"></i>
    </Button>
  );
};

export default VKAuthBtn;
