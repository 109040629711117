export const SET_LOADING = "SET_LOADING";

export const SET_WISHLIST = "SET_WISHLIST";
export const SET_ADDING_NEW_ITEM = "SET_ADDING_NEW_ITEM";
export const SET_EDITED_ITEM = "SET_EDITED_ITEM";
export const SET_NEW_GIFTER_MODAL = "SET_NEW_GIFTER_MODAL";

export const SET_IS_AUTHORIZED = "SET_IS_AUTHORIZED";
export const SET_USER = "SET_USER";
export const SET_LOADING_AUTH = "SET_LOADING_AUTH";

export const PUSH_ALERT = "PUSH_ALERT";
export const CHANGE_ALERT_DATA = "CHANGE_ALERT_DATA";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";

export const SET_FAVORITE_USERS = "SET_FAVORITE_USERS";
