import styled from "styled-components";

export const Button = styled.button`
  width: 8ch;
  padding: 1rem 1rem 1rem 1.3rem;
  margin: 0.5rem;
  border: none;
  border-radius: 3px;
  cursor: pointer;

  :hover {
    background-color: ${(props) => props.theme.PRIMARY};
  }

  i {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
`;
