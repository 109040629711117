import React, { useState, useEffect, useContext } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import AuthContext from "../../context/auth/authContext";
import AlertContext from "../../context/alert/alertContext";

import { Button } from "./AuthBtnStyle";

const FacebookAuthBtn = ({ btnType }) => {
  const { login, register } = useContext(AuthContext);
  const { pushAlert } = useContext(AlertContext);
  const [btnTitle, setBtnTitle] = useState();

  useEffect(() => {
    if (btnType === "login") {
      setBtnTitle("Войти с учетной записью Facebook");
    } else if (btnType === "register")
      setBtnTitle("Зарегистрироваться с учетной записью Facebook");
  }, []);

  // if user logs in wigh facebook, log in or register new user
  // if trying to register, uses login() if user is already registered
  // show error message if facebook didn't respond right
  const responseFacebook = (response) => {
    const { name, id } = response;
    if (response.id) {
      if (btnType === "login") {
        login({ name, external_id: id });
      } else if (btnType === "register") register({ name, external_id: id });
    } else if (response.status === "unknown") {
    } else {
      pushAlert({
        type: "danger",
        text:
          "Ошибка авторизации через Facebook. Проверьте логин, пароль и попробуйте снова",
      });
    }
  };
  return (
    <FacebookLogin
      appId={
        process.env.NODE_ENV === "production"
          ? "1038415999953068"
          : "1036054240194241"
      }
      isMobile={false}
      fields="name"
      callback={responseFacebook}
      size="small"
      icon="fa-facebook"
      render={(renderProps) => (
        <Button
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          title={btnTitle}
        >
          <i class="fab fa-facebook-f"></i>
        </Button>
      )}
    />
  );
};

export default FacebookAuthBtn;
